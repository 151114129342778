import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g86.jpg";
import Img2 from "../../../assets/gallery/g87.jpg";
import Img3 from "../../../assets/gallery/g88.jpg";
import Img4 from "../../../assets/gallery/g89.jpg";
import Img5 from "../../../assets/gallery/g90.jpg";
import Img6 from "../../../assets/gallery/g91.jpg";
import Img7 from "../../../assets/gallery/g92.jpg";
import Img8 from "../../../assets/gallery/g93.jpg";
import Img9 from "../../../assets/gallery/g94.jpg";
import Img10 from "../../../assets/gallery/g95.jpg";
import Img11 from "../../../assets/gallery/g96.jpg";
import Img12 from "../../../assets/gallery/g97.jpg";
import Img13 from "../../../assets/gallery/g98.jpg";
import Img14 from "../../../assets/gallery/g99.jpg";

export default function Event8() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
  ];

  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">
                  Breaking Barriers in Pulmonary Care: Cryotherapy Workshop at
                  Ashwini Group of Hospitals
                </h1>
              </div>
              <p className="intro">
                The Department of Pulmonary Medicine at Ashwini Group of
                Hospitals recently hosted a transformative Hands-On Training
                Workshop on Cryotherapy in Lung Diseases, marking a significant
                leap forward in pulmonary care and innovation.
              </p>
              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="Cryotherapy Workshop"
                  title="Cryotherapy Workshop"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The event was graced by distinguished dignitaries,
                      including Dr. Maya Gantayet, Managing Director, Ashwini
                      Group of Hospitals, who attended as the Chief Guest.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The occasion was further honored by Prof. Dr. M.R.
                      Pattnaik and Prof. Dr. Jyoti Pattnaik, who joined as
                      Guests of Honour. Additionally, Dr. Subrat Jena, Chairman
                      of Ashwini Group of Hospitals, played a pivotal role in
                      chairing and inaugurating the event.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="Cryotherapy Workshop"
                  title="Cryotherapy Workshop"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Behind the seamless execution of the workshop was the
                      dedicated organizing team, led by Dr. Sampat Dash and Dr.
                      Satya Ranjan Mohanty, who coordinated every aspect with
                      precision and professionalism.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The workshop featured engaging sessions by eminent
                      national and state-level faculty, delivering
                      thought-provoking insights into the latest advancements in
                      cryotherapy.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      These talks highlighted the growing importance of this
                      innovative technique in managing lung diseases.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="Cryotherapy Workshop"
                  title="Cryotherapy Workshop"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      A key highlight of the event was the interactive hands-on
                      training sessions, where participants actively practiced
                      and honed their skills under expert guidance.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The enthusiasm of attendees, coupled with the expertise of
                      the faculty, created an environment of intense learning
                      and collaboration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Image Preview
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <LazyLoadImage
                        src={currentImage}
                        alt="Cryotherapy Workshop"
                        title="Cryotherapy Workshop"
                        effect="blur"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
