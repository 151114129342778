import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g63.jpg";
import Img2 from "../../../assets/gallery/g59.jpg";
import Img3 from "../../../assets/gallery/g53.jpg";
import Img4 from "../../../assets/gallery/g54.jpg";
import Img5 from "../../../assets/gallery/g55.jpg";
import Img6 from "../../../assets/gallery/g56.jpg";
import Img7 from "../../../assets/gallery/g57.jpg";
import Img8 from "../../../assets/gallery/g58.jpg";
import Img9 from "../../../assets/gallery/g60.jpg";
import Img10 from "../../../assets/gallery/g61.jpg";
import Img11 from "../../../assets/gallery/g62.jpg";
import Img12 from "../../../assets/gallery/g64.jpg";
import Img13 from "../../../assets/gallery/g65.jpg";
import Img14 from "../../../assets/gallery/g66.jpg";
import Img15 from "../../../assets/gallery/g67.jpg";
import Img16 from "../../../assets/gallery/g68.jpg";

export default function Event2() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
  ];

  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">ARDS-Update Workshop</h1>
              </div>
              <p className="intro">
                Ashwini Group of Hospitals is proud to announce the success of
                its first ARDS-Update Workshop held in Cuttack, organized by the
                esteemed Department of Critical Care. This landmark event
                brought together top critical care experts from across India,
                healthcare professionals, and participants eager to advance
                their knowledge in Acute Respiratory Distress Syndrome.
                Ashwini’s commitment to medical excellence and innovation in
                critical care was at the heart of this workshop, designed to
                elevate patient outcomes and foster a culture of continual
                learning.
              </p>
              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="ARDS-Update Workshop"
                  title="ARDS-Update Workshop"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">Workshop Highlights</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The workshop opened with dynamic lectures led by some of
                      India’s most respected critical care specialists, covering
                      the latest advancements in the field and key
                      methodologies.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      These sessions underscored Ashwini Group of Hospitals'
                      dedication to enhancing healthcare standards and
                      patient-centered care in the region.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="ARDS-Update Workshop"
                  title="ARDS-Update Workshop"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">
                    Interactive Training & Knowledge Sharing
                  </p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Participants were offered hands-on training, guided by
                      experts, which allowed them to refine their skills and
                      techniques.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      This interactive format encouraged collaboration and
                      offered valuable insights into critical care best
                      practices—one of many initiatives by Ashwini Group of
                      Hospitals to drive professional growth and medical
                      expertise.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="ARDS-Update Workshop"
                  title="ARDS-Update Workshop"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">
                    Celebrating Medical Excellence and Learning
                  </p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Ashwini Group of Hospitals is honored to have hosted this
                      impactful workshop, furthering its mission to lead in
                      healthcare innovation and patient care excellence.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The event served as a significant milestone for Ashwini,
                      building a foundation for more educational events to
                      benefit the healthcare community.
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Image Preview
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <LazyLoadImage
                        src={currentImage}
                        alt="ARDS-Update Workshop"
                        title="ARDS-Update Workshop"
                        effect="blur"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
