import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g39.jpg";
import Img2 from "../../../assets/gallery/g32.jpg";
import Img3 from "../../../assets/gallery/g33.jpg";
import Img4 from "../../../assets/gallery/g34.jpg";
import Img5 from "../../../assets/gallery/g35.jpg";
import Img6 from "../../../assets/gallery/g36.jpg";
import Img7 from "../../../assets/gallery/g37.jpg";
import Img8 from "../../../assets/gallery/g38.jpg";
import Img9 from "../../../assets/gallery/g30.jpg";

export default function Event5() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [Img4, Img5, Img6, Img7, Img8, Img9];
  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">Neuro-Radiology for Clinicians</h1>
              </div>
              <p className="intro">
                Reflecting on an inspiring and transformative Neuro-Radiology
                for Clinicians Workshop! The Ashwini Group of Hospitals proudly
                hosted this impactful event, uniting some of the finest minds in
                neurology and radiology to share knowledge, explore innovations,
                and drive progress in patient care.
              </p>
              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="Neuro-Radiology for Clinicians"
                  title="Neuro-Radiology for Clinicians"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      With groundbreaking insights and collaborative
                      discussions, this workshop underscored our unwavering
                      commitment to advancing healthcare and enhancing
                      diagnostic precision in neuro-radiology.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      From case studies to the latest imaging techniques, every
                      session was a testament to our dedication to clinical
                      excellence and innovation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="Neuro-Radiology for Clinicians"
                  title="Neuro-Radiology for Clinicians"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p>{}</p>
                  <div className="sec-one-shape">
                    <p>
                      Here's to the power of collaboration and a shared vision
                      for a healthier tomorrow. Together, we’re making strides
                      in healthcare that truly make a difference.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="Neuro-Radiology for Clinicians"
                  title="Neuro-Radiology for Clinicians"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p>{}</p>
                  <div className="sec-one-shape">
                    <p>
                      Cheers to continued progress and impactful learning at
                      Ashwini Group of Hospitals!
                    </p>
                  </div>
                </div>
              </div>

              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Image Preview
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <LazyLoadImage
                        src={currentImage}
                        alt="Neuro-Radiology for Clinicians"
                        title="Neuro-Radiology for Clinicians"
                        effect="blur"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
