import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g71.jpg";
import Img2 from "../../../assets/gallery/g70.jpg";
import Img3 from "../../../assets/gallery/g72.jpg";
import Img4 from "../../../assets/gallery/g69.jpg";
import Img5 from "../../../assets/gallery/g73.jpg";
import Img6 from "../../../assets/gallery/g74.jpg";
import Img7 from "../../../assets/gallery/g75.jpg";
import Img8 from "../../../assets/gallery/g76.jpg";
import Img9 from "../../../assets/gallery/g77.jpg";
import Img10 from "../../../assets/gallery/g78.jpg";
import Img11 from "../../../assets/gallery/g79.jpg";
import Img12 from "../../../assets/gallery/g80.jpg";

export default function Event1() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [Img4, Img5, Img6, Img7, Img8, Img9, Img10, Img11, Img12];
  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">Joint Reconstruction Workshop</h1>
              </div>
              <p className="intro">
                Recently, the Ashwini Group of Hospitals’ Department of Cleft
                and Maxillofacial Surgery proudly organized a pre-conference
                workshop on TM Joint Reconstruction as part of the 3rd AOMSI
                Odisha State Chapter Conference. This workshop brought together
                maxillofacial surgeons, residents, and experts to explore and
                enhance surgical techniques in temporomandibular joint
                reconstruction. Here’s a glimpse into the highlights of this
                transformative day.
              </p>
              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="Joint Reconstruction Workshop"
                  title="Joint Reconstruction Workshop"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">Workshop Highlights</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The workshop began with a comprehensive lecture series
                      covering the latest advancements and practical approaches
                      in TM Joint Reconstruction.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Our experienced faculty shared insights on new surgical
                      procedures and treatment modalities, focusing on
                      functional restoration and patient-centered outcomes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="Joint Reconstruction Workshop"
                  title="Joint Reconstruction Workshop"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">
                    Hands-On Training & Demonstrations
                  </p>
                  <div className="sec-one-shape">
                    {/* <LazyLoadImage src={Shape1} alt="shape" /> */}
                    <p>
                      Participants engaged in hands-on sessions led by our
                      expert surgeons, practicing advanced techniques and
                      refining their skills with specialist guidance in a
                      collaborative setting.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="Joint Reconstruction Workshop"
                  title="Joint Reconstruction Workshop"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">
                    A Day of Learning and Collaboration
                  </p>
                  <div className="sec-one-shape">
                    {/* <LazyLoadImage src={Shape1} alt="shape" /> */}
                    <p>
                      The event provided a unique platform for practitioners and
                      trainees to connect, share knowledge, and discuss cases,
                      marking it as a cornerstone event for professional growth
                      in the field of maxillofacial surgery.
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Image Preview
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <LazyLoadImage
                        src={currentImage}
                        alt="Joint Reconstruction Workshop"
                        title="Joint Reconstruction Workshop"
                        effect="blur"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
