import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g44.jpg";
import Img2 from "../../../assets/gallery/g45.jpg";
import Img3 from "../../../assets/gallery/g42.jpg";
import Img4 from "../../../assets/gallery/g40.jpg";
import Img5 from "../../../assets/gallery/g41.jpg";
import Img6 from "../../../assets/gallery/g43.jpg";
import Img7 from "../../../assets/gallery/g47.jpg";
import Img8 from "../../../assets/gallery/g48.jpg";

export default function Event6() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [Img4, Img5, Img6, Img7, Img8];
  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">Neuro-Immunology Workshop</h1>
              </div>

              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="Neuro-Immunology Workshop"
                  title="Neuro-Immunology Workshop"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Reflecting on an incredible gathering of minds! The
                      Ashwini Group of Hospitals was honored to host a
                      transformative Neuro-Immunology Workshop, bringing
                      together leading experts in neurology and immunology.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      This event highlighted cutting-edge insights,
                      collaborative discussions, and a shared commitment to
                      advancing healthcare for patients facing complex
                      neurological and immunological conditions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="Neuro-Immunology Workshop"
                  title="Neuro-Immunology Workshop"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      From breakthrough research to new approaches in treatment,
                      the workshop underscored our dedication to fostering
                      innovation and teamwork in healthcare.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      We’re energized by the progress made and look forward to
                      further driving excellence and collaboration in patient
                      care.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="Neuro-Immunology Workshop"
                  title="Neuro-Immunology Workshop"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <p>
                      Stay tuned as we continue to shape the future of
                      healthcare together at Ashwini Group of Hospitals!
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <LazyLoadImage
                      src={currentImage}
                      alt="Neuro-Immunology Workshop"
                      title="Neuro-Immunology Workshop"
                      effect="blur"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
